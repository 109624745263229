<template>
  <div class="info-box d-flex mb-3" @click="selectOffice(infoData)">
    <div :class="['icon', $activeLang == 'ar' ? 'ms-3' : 'me-3']" :style="`color: ${infoData.color}`">
      <fa :icon="['fa', infoData.icon]" />
    </div>
    <div :class="[$activeLang == 'ar' ? 'text-end' : 'text-start']" class="informations">
      <strong class="mb-2 d-block"><img class="flag" :src="infoData.flag" v-if="infoData.flag"
          :alt="infoData.title + ' Flag'" />{{ infoData.title }}</strong>
      <a :href="infoData.link" :class="['d-block', 'no-link-style', 'm-0', infoData.link ? 'en' : '']" dir="ltr">{{
          infoData.info
      }}</a>
      <div class="sub-info en" v-if="infoData.subInfo" dir="ltr">
        <fa :icon="['fa', infoData.subInfo.icon]" v-if="$activeLang != 'ar'" /> {{ infoData.subInfo.info }}
        <fa :icon="['fa', infoData.subInfo.icon]" v-if="$activeLang == 'ar'" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["infoData"],
  methods: {
    selectOffice(office) {
      window.open(office.subInfo.link);
    },
  },
};
</script>

<style>
</style>